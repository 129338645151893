import * as Icon from 'devicons-react'
import data from '../../config.json'


const aboutData = data.pt.about;

export const Skills = (customClass) => {
    
    return (
        <div className={{customClass}}>
            <div className='mb-8 dark:text-warm-gray-50 justify-center text-center px-8 text-lg'>{aboutData.text_tech_skills}</div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2  gap-8">
                <Icon.FlutterPlain size={75} color='gray' className='mx-auto' />
                <Icon.AndroidPlain size={75} color='gray' className='mx-auto'/>
                <Icon.SwiftPlain size={75} color='gray' className='mx-auto'/>
                <Icon.LaravelPlain size={75} color='gray' className='mx-auto'/>
                <Icon.DartPlain size={75} color='gray'className='mx-auto'/>
                <Icon.DockerPlain size={75} color='gray' className='mx-auto'/>
                <Icon.PhpPlain size={75} color='gray' className='mx-auto'/>
                <Icon.PythonPlain size={75} color='gray' className='mx-auto'/>
                <Icon.JavaPlain size={75} color='gray' className='mx-auto'/>
                <Icon.KotlinPlain size={75} color='gray' className='mx-auto'/>
                <Icon.GitPlain size={75} color='gray' className='mx-auto'/>
                <Icon.AngularjsPlain size={75} color='gray' className='mx-auto'/>
            </div>
            <div className='mt-8 dark:text-warm-gray-50 justify-center text-center px-8 text-lg'>{aboutData.text_personal_skills}</div>
        </div>
    )
}